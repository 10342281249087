body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

canvas {
  opacity: 1;
  transition: opacity 4.528s;
}

canvas.fade-out {
  opacity: 0;
}